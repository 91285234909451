@import "./styles/Spacing.less";
@import "./styles/MediaQueries.less";

body {
    margin: 0;
}

* {
    box-sizing: border-box;
}

.kal-card {
    margin-bottom: @spacer-desktop-06;
    padding-top: @spacer-desktop-06;
    padding-bottom: @spacer-desktop-06;

    > .kal-heading {
        &-2,
        &-3 {
            margin-top: 0;
        }
    }

    @media @mobile {
        margin-bottom: @spacer-mobile-06;
    }
}

.kal-page-background {
    padding-top: 0;
}

.kal-form-errors {
    .kal-alert {
        margin-top: 0;
    }
}

.kal-form-field {
    &,
    &.kal-switch-list {
        @media @mobile-spacing {
            margin-top: @spacer-mobile-05;
        }
        @media @desktop-spacing {
            margin-top: @spacer-desktop-05;
        }
    }
}

.kal-heading-1 {
    margin-bottom: @spacer-desktop-06;
}

.ql-editor {
    min-height: 96px;
}

fieldset:not(.kal-switch-list):not(.kal-form-field) {
    padding: 0;
    margin: 0;
    border: 0;
}