@import "../styles/Spacing.less";

.page {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 16px;

    .wrapper {
        max-width: 800px + (32px * 2);
        width: 100%;
        .padding-responsive();
        padding-top: 0;
    }
}